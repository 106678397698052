import { ReactElement } from "react";
import KeepAlive from "react-activation";
import HomePage from "./pages/home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardPage from "./pages/dashboard";
import ReviewPage from "./pages/review";
import SettingPage from "./pages/settings";

export interface IRouteItem {
  key: string;
  name: string;
  icon?: ReactElement;
  route?: {
    path: string;
    component: ReactElement;
  };
  collapse?: IRouteItem[];
}

const routes: IRouteItem[] = [
  {
    key: "home",
    name: "Home",
    icon: <DashboardIcon />,
    route: {
      path: "/home",
      component: (
        <KeepAlive id="homepage">
          <HomePage />
        </KeepAlive>
      ),
    },
  },
  {
    key: "dashboard",
    name: "Dashboard",
    icon: <DashboardIcon />,
    route: {
      path: "/dashboard",
      component: (
        <KeepAlive id="dashboard">
          <DashboardPage />
        </KeepAlive>
      ),
    },
  },
  {
    key: "review",
    name: "Review",
    icon: <DashboardIcon />,
    route: {
      path: "/review",
      component: (
        <KeepAlive id="review">
          <ReviewPage />
        </KeepAlive>
      ),
    },
  },
  {
    key: "setting",
    name: "Setting",
    icon: <DashboardIcon />,
    route: {
      path: "/setting",
      component: <SettingPage />,
    },
  },
];

export default routes;
